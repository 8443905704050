<template>
  <section class="login">
    <div class="login__container" v-if="view === 'default'">
      <h1 class="login__headline">Willkommen bei Sokratext.</h1>
      <form class="form login__form" method="post" data-login-form>
        <div class="form__row">
          <div class="form__element">
            <label class="label form__label" for="email">E-Mail-Adresse*</label>
            <input class="input" id="email" name="email" type="email" data-form-input/>
          </div>
        </div>
        <div class="form__row">
          <div class="form__element">
            <label class="label form__label" for="password">Passwort*</label>
            <input class="input" id="password" name="password" type="password" data-form-input/>
          </div>
        </div>

        <div class="login__error" data-login-error>
          E-Mail-Adresse oder Passwort ist leider nicht korrekt.
        </div>
        <button type="submit" class="button -login -blue" @click="submitLogin($event)">
          Anmelden
        </button>
      </form>
      <button class="link" @click="view = 'forgotPassword'">
        Passwort vergessen?
      </button>
    </div>
    <div class="login__container" v-if="view === 'forgotPassword'">
      <h1 class="login__headline">Passwort zurücksetzen?</h1>
      <p class="login__text">
        Geben Sie bitte die E-Mail-Adresse ein, mit der Sie Ihren Account erstellt haben. Wir schicken Ihnen dann einen Link zum Zurücksetzen Ihres Passwortes zu.
      </p>
      <form class="form login__form" method="post" data-password-reset-form>
        <div class="form__row">
          <div class="form__element">
            <label class="label form__label" for="email">E-Mail-Adresse*</label>
            <input class="input" id="email" name="email" type="email" data-form-input/>
          </div>
        </div>
        <button type="submit" class="button -login -blue" @click="submitPasswordReset($event)">
          Absenden
        </button>
      </form>
      <button class="link" @click="view = 'default'">
        Zurück zum Login
      </button>
    </div>
    <div class="login__container" v-if="view === 'mailRequested'">
      <h1 class="login__headline">Passwort zurücksetzen.</h1>
      <p class="login__text">
        Eine E-Mail mit einem Link zum Zurücksetzen des Passworts wurde an Ihre Adresse versandt.
      </p>
      <button class="link" @click="view = 'default'">
        Zurück zum Login
      </button>
    </div>
    <div class="login__container" v-if="view === 'setPassword'">
      <h1 class="login__headline">Neues Passwort bestimmen</h1>
      <p class="login__text">
        Bitte geben Sie Ihr neues Passwort ein:
      </p>
      <form class="form login__form" method="post" data-password-change-form>
        <div class="form__row">
          <div class="form__element">
            <label class="label form__label" for="password">Passwort*</label>
            <input class="input" id="password" name="password" type="password" data-form-input/>
          </div>
        </div>
        <div class="form__row">
          <div class="form__element">
            <label class="label form__label" for="password2">Passwort wiederholen*</label>
            <input class="input" id="password2" name="password2" type="password" data-form-input/>
          </div>
        </div>
        <div class="login__error" data-login-error>
          Die Passwörter stimmen leider nicht überein.
        </div>
        <button type="submit" class="button -login -blue" @click="submitNewPassword($event)">
          Passwort übernehmen
        </button>
      </form>
      <button class="link" @click="view = 'default'">
        Zurück zum Login
      </button>
    </div>
    <div class="login__container" v-if="view === 'passwordChanged'">
      <h1 class="login__headline">Das neue Passwort wurde übernommen.</h1>
      <button class="link" @click="view = 'default'">
        Zurück zum Login
      </button>
    </div>
    <div class="login__container" v-if="view === 'cookieExists'">
      <h1 class="login__headline">Account Daten werden geprüft.</h1>
    </div>
  </section>
</template>

<script>

export default {
  name: 'login',
  data() {
    return {
      view: this.resetToken? 'setPassword' : 'default',
    };
  },
  props: {
    resetToken: {
      type: String,
    },
  },
  methods: {
    validateForm(form) {
      // This function deals with validation of the form fields
      let valid = true;
      const inputs = form.querySelectorAll('[data-form-input]');
      // A loop that checks every input field in the current form:
      for (let i = 0; i < inputs.length; i++) {
        // If a field is empty...
        if (inputs[i].value === "") {
          // add an "invalid" class to the field:
          inputs[i].classList.add('-invalid');
          // and set the current valid status to false:
          valid = false;
        } else {
          inputs[i].classList.remove('-invalid');
        }
      }
      // If the valid status is true, mark the step as finished and valid:
      return valid;
    },
    compareValues(value1, value2) {
      return value1 === value2;
    },
    async submitLogin(event) {
      event.preventDefault()
      const form = document.querySelector('[data-login-form]');

      if(this.validateForm(form)) {
        const formData = new FormData(form);
        const loginError = form.querySelector('[data-login-error]');
        loginError.classList.remove('-visible');

        const response = await fetch('/api/v1/account/login', {
          method: 'POST',
          headers: {
            'accept': '*/*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(Object.fromEntries(formData))
        })
        const responseJson = await response.json();

        switch (response.status) {
          case 200:
            await this.$router.push({name:'Organizer', params: {
                customerId: responseJson.customer_code,
              }});
            break;
          case 401:
            loginError.classList.add('-visible');
            break;
          default:
            console.log(`(${response.status}): ${response.error}`)
        }
      }
    },
    async submitPasswordReset(event) {
      event.preventDefault()
      const form = document.querySelector('[data-password-reset-form]');

      if(this.validateForm(form)) {
        const formData = new FormData(form);

        const response = await fetch('/api/v1/account/password-reset', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(Object.fromEntries(formData))
        })

        switch (response.status) {
          case 201:
            this.view = 'mailRequested';
            console.log(response)
            return response;
          default:
            console.log(`(${response.status}): ${response.error}`);
        }
      }
    },
    async submitNewPassword(event) {
      event.preventDefault();
      const form = document.querySelector('[data-password-change-form]');
      const loginError = form.querySelector('[data-login-error]');
      const value1 = form.querySelector('#password').value;
      const value2 = form.querySelector('#password2').value;

      if(this.validateForm(form)) {
        if (this.compareValues(value1, value2)) {
          const formData = new FormData();
          formData.append('password', value1)
          loginError.classList.remove('-visible');

          const response = await fetch(`/api/v1/account/password-reset/${this.resetToken}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(Object.fromEntries(formData))
          })

          switch (response.status) {
            case 200:
              console.log(response)
              this.view = 'passwordChanged';
              break;
            default:
              console.log(`(${response.status}): ${response.error}`);
          }
        } else {
          loginError.classList.add('-visible');
        }
      }
    },
    doesCookieExist(cookiename) {
      let d = new Date();
      d.setTime(d.getTime() + (1000));
      let expires = "expires=" + d.toUTCString();

      document.cookie = cookiename + "=new_value;path=/;" + expires;
      return document.cookie.indexOf(cookiename + '=') === -1;
    },
    async whoAmI() {
      const response = await fetch('/api/v1/account/', {
        method: 'GET',
        headers: {
          'accept': '*/*',
          'Content-Type': 'application/json'
        },
      })
      let resJson = null;

      switch (response.status) {
        case 200:
          resJson = await response.json();
          await this.$router.push({name:'Organizer', params: {
              customerId: resJson.customer_code,
            }});
          break;
        default:
          this.logout();
      }

    }
  },
  async logout() {
    const response = await fetch('/api/v1/account/logout', {
      method: 'POST'
    });
    if (response.status === 200) {
      this.$router.push({name: 'Login'});
    } else {
      console.error('Logout request failed')
    }
  },
  mounted() {
    if (this.doesCookieExist('access_token')) {
      this.view = 'cookieExists';
      this.whoAmI();
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  height: calc(100vh - 60px);
  padding: rem(50px) 0;

  &__container {
    height: 100%;
    width: 100%;
    max-width: rem(560px);
    margin: 0 auto;
    padding: 0 rem(20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  &__headline {
    font-size: rem(40px);
    margin-bottom: rem(40px);
  }

  &__form {
    margin-bottom: rem(15px);
  }

  &__text {
    margin-bottom: rem(25px);
  }

  &__error {
    color: red;
    display: none;

    &.-visible {
      display: block;
    }
  }
}
</style>